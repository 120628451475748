import * as React from "react"
import {
  ChakraProvider,
  CSSReset
  
} from "@chakra-ui/react"
import MusicianSplashPage from "./MusicianSplashPage"
import ContactPage from "./ContactPage"
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; 
import NavBar from "./NavBar";
import BioPage from "./BioPage"
// import PianoHeader from "./PianoHeader"
import ShopPage from "./ShopPage"

export const App = () => (
  <ChakraProvider>
  <CSSReset />
  <Router> {/* Wrap the entire app with the Router */}
      <NavBar />
      <Routes>
        <Route path="/" element={<MusicianSplashPage />} /> {/* Use Routes to render MusicianSplashPage */}
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/bio" element={<BioPage />} />
        <Route path="/shop" element={<ShopPage />} />
        {/* Add more routes for other pages */}
      </Routes>
    </Router>
  {/* <MusicianSplashPage /> Use the MusicianSplashPage component here */}
  {/* Other components and content of your app */}
</ChakraProvider>
)
