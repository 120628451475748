// ShopPage.tsx

import * as React from 'react';
import { Box, Heading, SimpleGrid } from '@chakra-ui/react';
import axios, {AxiosResponse} from 'axios';

interface Category {
  id: number;
  name: string;
}

let response: AxiosResponse;

const ShopPage: React.FC = () => {
  const [categories, setCategories] = React.useState<Category[]>([]);

  React.useEffect(() => {
    // Fetch shop categories from the WooCommerce API
    const fetchCategories = async () => {
      try {
        if (!response){
          response = await axios.get(`https://wc-worker.nathan-geyer.workers.dev/api/products/categories`);
        }
        setCategories(response.data);
      } catch (error) {
        console.error('Error fetching shop categories:', error);
      }
    };

    fetchCategories();
  }, []);

  return (
    <Box p={8}>
      <Heading as="h1" size="xl" mb={4}>
        Shop Categories
      </Heading>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={4}>
        {categories.map((category) => (
          <Box key={category.id} p={4} borderWidth="1px" borderRadius="md" boxShadow="md">
            <Heading as="h2" size="md" mb={2}>
              {category.name}
            </Heading>
            {/* You can add more details about the category here */}
          </Box>
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default ShopPage;
