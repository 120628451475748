import React from 'react'
import { Flex, Grid, Heading, useColorMode, } from '@chakra-ui/react'
const PianoHeader: React.FC = () => {
  const { colorMode } = useColorMode();
  return (
    <Grid
      backgroundImage="./piano-inside.jpg"
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      height={['60vh', '60vh', '60vh', '70vh']}
      templateRows="1fr 1fr 1fr"
    >
      <Flex
        flexDirection="column"
        gridRow="3 / 3"
        paddingX={['1.5em', '1.5em', '1.5em', '15%']}
      >
        <Heading
          as="h2"
          fontSize={['4xl', '5xl', '6xl', '6xl', '6xl']}
          color={colorMode === 'light' ? 'black' : 'white'}
          bg={colorMode === 'light' ? 'white' : 'gray.00'}
          mb="3"
          width="fit-content"
          paddingX={['4px', '12px', '12px', '16px', '24px']}
          paddingY={['4px', '4px', '12px', '12px', '16px']}
        >
          Pam Asberry
        </Heading>
        <Heading
          as="h3"
          fontSize={['lg', 'lg', '2xl', '2xl', '2xl']}
          textTransform="uppercase"
          color={colorMode === 'light' ? 'black' : 'white'}
          bg={colorMode === 'light' ? 'white' : 'gray.00'}
          width="fit-content"
          paddingX={['24px', '24px', '30px', '30px', '30px']}
          paddingY={['8px', '8px', '14px', '14px', '14px']}
        >
          Pianist | Composer | Teacher
        </Heading>
      </Flex>
    </Grid>
  )
}

export default PianoHeader