import {
  chakra,
  Container,
  Stack,
  Text,
  Button,
  Image,
  Skeleton,
  Box,
} from '@chakra-ui/react';

const BioPage = () => {
  return (
    <Container maxW="6xl" px={{ base: 4, md: 8 }} py={24}>
      <Stack direction={{ base: 'column', md: 'row' }} justifyContent="center">
        <Stack direction="column" spacing={6} justifyContent="center" maxW="480px">
          <chakra.h1 fontSize="5xl" lineHeight={1} fontWeight="bold" textAlign="left">
            More about Pam
          </chakra.h1>
          <Text
            fontSize="1.2rem"
            textAlign="left"
            lineHeight="1.375"
            fontWeight="400"
            color="gray.500"
          >
            Described as “a new age solo pianist of uncommon melodic grace, insight and heartfelt intuitiveness” (Jonathan Widran, The JW Vibe), Pam Asberry is an award-winning performing artist and composer who combines contemporary, new age and classical sounds to create music that is alternately melodic and graceful, dramatic and powerful, peaceful and hypnotic. Classically trained from the age of seven, she completed a master’s degree in piano performance and piano pedagogy and maintains a private teaching studio in Lawrenceville, Georgia, offering both virtual and in-person lessons in piano, clarinet, music theory, and composition. She has played concerts across the United States and abroad and her twin passions are sharing her music and the stories behind them with listeners around the world and inspiring others, especially young people, to find their own joy in music making.

A BMI artist, she released her first album of original solo piano music, Seashells in My Pocket, in October 2017. Seashells in My Pocket was nominated for Best Solo Piano album at One World Music Radio and Pam was named Best New Artist 2018 at Enlightened Piano Radio. Thankful Heart, Joyful Mind (October 2018), a collection of holiday arrangements, was nominated for Best Holiday Album at SoloPiano.com and awarded Best Holiday Album at Enlightened Piano Radio. The Presence of Wonder (April 2019) reached #7 on the April 2019 Zone Music Reporter chart, climbed to #1 on the July 2019 One World Music Radio chart, and was nominated for Best Solo Piano album at One World Music Radio. All Through the Night (October 2019) is a soothing collection of original pieces, traditional lullabies from around the world, and fresh arrangements of classical works. Her fifth album, Moods, Impressions & Souvenirs (April 2020), spent two months on the Zone Music Reporter chart at #7 and was nominated for Best Solo Piano Album at SoloPiano.com. Twelvemonth (April 2021) is a musical journey throughout the year. Inspired by Fanny Mendelssohn Hensel’s Das Jahr, it consists of twelve character pieces representing January through December. It reached #7 on the May 2021 Zone Music Reporter chart, climbed to #1 on the May 2021 One World Music Radio chart, and was awarded Best Solo Piano album at One World Music Radio. Amazing Grace (November 2021) is a collection of eighteen of the world’s most beloved hymns arranged for solo piano. Pam’s first 2022 release, Unraveling, is the musical expression of her personal experience navigating through the coronavirus crisis. The Weary World Rejoices (October, 2022) is her second collection of joyful holiday tunes.

Pam’s music can be heard on Whisperings Solo Piano Radio, Spotify, Pandora, Apple Music, Sirius XM, The River of Calm, and elsewhere. Please leave a message on our contact page to find out more.
          </Text>
          <Button
            w={{ base: '100%', sm: 'auto' }}
            h={12}
            px={6}
            color="white"
            rounded="md"
            zIndex={5}
            lineHeight={1}
            // bgGradient="linear(to-l, #0ea5e9,#2563eb)"
            // _hover={{ bgGradient: 'linear(to-l, #0ea5e9,#2563eb)', opacity: 0.9 }}
          >
            View More
          </Button>
        </Stack>
        <Box ml={{ base: 0, md: 5 }} pos="relative">
          {/* Replace this Image with your own bio image */}
          <Image
            w="100%"
            h="100%"
            minW={{ base: 'auto', md: '30rem' }}
            objectFit="cover"
            src={`https://images.unsplash.com/photo-1511556820782-1924e389eacb?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&auto=format&fit=crop&w=334&q=80`}
            rounded="md"
            fallback={<Skeleton />}
          />
        </Box>
      </Stack>
    </Container>
  );
};

export default BioPage;
