// MusicianSplashPage.tsx

import * as React from 'react';
import {
  Box,
  Text,
  useColorMode,
  Image,
  Flex,
  Spacer,
  Card,
  CardBody,
} from '@chakra-ui/react';
import PianoHeader from './PianoHeader';
import { 
    FaFacebook,
    FaInstagram,
    FaTwitter
 } from 'react-icons/fa';

const MusicianSplashPage: React.FC = () => {
  const { colorMode } = useColorMode();
  // const [isSmallerScreen] = useMediaQuery('(max-width: 768px)');

  return (
    <Box minH="100vh" color={`linear(to-r, ${colorMode === 'light' ? '#F3EED9' : 'gray.800'}, ${colorMode === 'light' ? 'cyan.300' : 'gray.900'})`}>
    <PianoHeader></PianoHeader>
      {/* <Center h="100%">
      </Center> */}
      <Flex alignItems="center" maxW="800px" mx="auto" p="4">
          <Box flex="4">
                <Card
            maxW="600px"
            mx="auto"
            my="20px"
            p="20px"
            boxShadow="md"
            borderRadius="lg"
            background='gray.200'
            // bgGradient="linear(to-r, #F3EED9, gray.800, cyan.300)"
            >
            <CardBody>
                <Flex direction={{ base: 'column', md: 'row' }} align="center">
                {/* Image */}
                <Image
                    src="Asberry-Square.jpg" // Replace with the actual image path
                    alt="Pam"
                    borderRadius="full"
                    boxSize="150px"
                    mr={{ base: 0, md: 4 }}
                    mb={{ base: 4, md: 0 }}
                />

                {/* Text */}
                <Box flex={{ base: 1, md: 2 }}>
                    <Text fontSize="xl" fontWeight="bold" mb="1rem">
                    Pam Asberry - Pianist & Composer
                    </Text>
                    <Text>
                    Described as “a new age solo pianist of uncommon melodic grace, insight, and heartfelt intuitiveness” (Jonathan Widran, The JW Vibe), Pam Asberry is an award-winning performing artist and composer who combines contemporary, new age, and classical sounds to create music that is alternately melodic and graceful, dramatic and powerful, peaceful and hypnotic.
                    </Text>
                </Box>
                </Flex>

                {/* Social Icons */}
                <Flex mt="2rem" justify="center">
                <Box as="a" href="#" mr="1rem">
                    <FaFacebook size={30} color="#3b5998" />
                </Box>
                <Box as="a" href="#" mr="1rem">
                    <FaInstagram size={30} color="#E4405F" />
                </Box>
                <Box as="a" href="#">
                    <FaTwitter size={30} color="#1da1f2" />
                </Box>
                </Flex>
            </CardBody>
            </Card>
          </Box>
          <Spacer />
        </Flex>
    </Box>
  );
};

export default MusicianSplashPage;
