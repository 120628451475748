import * as React from 'react';
import { Box, Flex, IconButton, useColorMode, Image, Spacer, useDisclosure } from '@chakra-ui/react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { NavLink as Link } from 'react-router-dom';

const NavBar: React.FC = () => {
  const { colorMode } = useColorMode();
  const { isOpen, onToggle } = useDisclosure();

    return (
    <Box as="nav" py={4} bg={colorMode === 'light' ? 'white' : 'gray.800'} color={colorMode === 'light' ? '#798897' : '#F3EED9'}>
        <Flex alignItems="center" maxW="800px" mx="auto">
        <Link to="/">
            <Image src="./flamingo1.png" boxSize="100px" alt="Logo" />
        </Link>
        <Box flex="1" />
        {/* Hamburger Menu Icon */}
        <IconButton
            aria-label="Open Menu"
            icon={<GiHamburgerMenu />}
            size="lg"
            display={{ base: 'flex', md: 'none' }} // Show on small screens, hide on medium and larger screens
            onClick={onToggle}
        />
        {/* Links */}
        <Flex
            display={{ base: isOpen ? 'flex' : 'none', md: 'flex' }} // Show on small screens when menu is open, show on medium and larger screens
            flexDirection={{ base: 'column', md: 'row' }}
        >
            <NavLink to="/">Home</NavLink>
            <NavLink to="/bio">Bio</NavLink>
            <NavLink to="/shop">Shop</NavLink>
            <NavLink to="/contact">Contact</NavLink>
        </Flex>
        <Spacer />
        </Flex>
    </Box>
    );
    };


interface NavLinkProps {
  to: string;
  children: React.ReactNode;
}

const NavLink: React.FC<NavLinkProps> = ({ to, children }) => {
  return (
    <Link to={to}>
      <Box
        paddingX={2}
        paddingY={1}
        rounded="md"
        color="gray.800"
        _hover={{ textDecoration: 'underline' }}
      >
        {children}
      </Box>
    </Link>
  );
};

export default NavBar;
